@media (max-width: 450px) {
  .switchButton div {
    padding: 15px 0px 10px 15px;
    line-height: 30px;
    margin-right: 20px;
    border-right: 0px;
  }

  #searchbar {
    padding: 10px 15px;
    height: 40px;
    font-size: 13px;
  }

  #searchButton {
    margin-left: -40px;
    padding-top: 7px;
  }

  .table100-body2 {
    max-height: unset;
  }

  #sideTabBar li {
    padding: 10px 33px;
  }

  .nav-tabs {
    border-bottom: none;
  }

  .setminhgt-tblbd2 {
    min-height: unset;
    margin-top: 20px;
  }

  .table100-body1 {
    max-height: unset;
  }

  #tabBar li {
    padding: 10px 15px;
  }

  #export {
    margin-top: -50px;
  }

  .filtertwo {
    text-align: center;
    box-shadow: none;
  }

  .commondiv {
    padding: 5px 14px;
  }

  .filter1 .commondiv-div1 {
    font-size: 14px;
  }

  .filter1 .commondiv-div2 {
    font-size: 14px;
  }

  .filter2 .commondiv-div1 {
    font-size: 14px;
  }

  .filter2 .commondiv-div2 {
    font-size: 14px;
  }

  .filter3 .commondiv-div1 {
    font-size: 14px;
  }

  .filter3 .commondiv-div2 {
    font-size: 14px;
  }

  .filter4 .commondiv-div1 {
    font-size: 14px;
  }

  .filter4 .commondiv-div2 {
    font-size: 14px;
  }

  .filter5 .commondiv-div1 {
    font-size: 14px;
  }

  .filter5 .commondiv-div2 {
    font-size: 14px;
  }

  .paytable .table100.ver1 {
    width: 1200px;
  }

  .filterdroplist {
    width: auto;
  }

  .settingbox {
    min-height: auto !important;
  }
}
