.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table100.ver1 {
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
}

.table100 {
  background-color: #fff;
}

.table100 {
  position: relative;
  padding-top: 60px;
}

.table100-head {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

table {
  width: 100%;
}

.table100.ver1 th {
  cursor: pointer;
  font-size: 13px;
  color: #fff;
  line-height: 1.4;
  background-color: #2a7fff;
}

.table100-head th {
  padding-top: 16px;
  padding-bottom: 16px;
}

.dcolumn8,
.dcolumn9 {
  width: 5%;
  text-align: center;
}

.divider {
  border-top: 1px solid #ddd;
  padding: 15px 0px;
  margin-top: 10px;
}

.payrollmanage {
  min-height: 600px;
}

.payrolltable table {
  border-bottom: 1px solid #d7d7d7;
  border-collapse: collapse;
  width: 100%;
}

.payrolltable td,
.payrolltable th {
  text-align: left;
  padding: 8px;
  vertical-align: top;
}

.payrolltable th {
  vertical-align: middle;
  background: linear-gradient(to bottom, #777777 0%, #444444 100%) !important;
  color: #fff;
  font-weight: normal;
}

.payrolltable td {
  border: 1px solid #e2e2e2;
}

.payrolltable tr:nth-child(even) {
  background-color: #ececec;
}

.payrolltable table tbody tr td a {
  font-size: 14px;
}

.payrollsumbtn {
  font-size: 14px;
  box-shadow: none;
  border: none;
  outline: none;
}

.payrolltable .editdltbtn {
  box-shadow: none;
  border: none;
  outline: none;
  color: #007bff;
}

.color-blue {
  color: #007bff;
}

.payrolltable .form-group {
  margin-bottom: 0;
}
