.react-table {
  width: 100%;
  border-collapse: collapse;
}

.react-table .thead {
  background: linear-gradient(180deg, #777 0, #444);
  border-color: #888;
  border-radius: 5px;
  font-size: 13px;
  line-height: 1.4;
  padding: 10px 0;
}

.react-table .thead th {
  font-weight: 500;
}

.react-table .thead th:first-child,
.react-table .tbody td:first-child {
  padding-left: 20px;
}
.react-table tbody tr {
  border-bottom: 1px solid #0000000d;
  box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.122);
  color: #333;
}

.react-table tbody tr:nth-child(odd) {
  background-color: #00000008;
}

.react-table td {
  padding: 20px 10px;
  font-size: 13px;
}

.react-table tbody tr:hover {
  background-color: #f1f1f1;
  box-shadow: 0 2px 5px 0 #dadce0;
}

.react-table .sort-icons {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}

.react-table .sort-icons .fas {
  display: block;
  font-size: 16px;
  line-height: 8px;
}

.react-table .sort_asc .fa-caret-down,
.react-table .sort_desc .fa-caret-up {
  color: #a9a9a9;
}

.resizer,
.drag {
  position: absolute;
  top: 0;
  height: 100%;
  width: 5px;
  background: rgba(0, 0, 0, 0.5);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.drag {
  width: 20px;
  right: 10px;
  top: 50%;
  transform: translateY(-10%);
  background: transparent;
}

.resizer.ltr {
  right: 0;
}

.resizer.rtl {
  left: 0;
}

.resizer.isResizing {
  background: #a9a9a9;
  opacity: 1;
}

@media (hover: hover) {
  .drag,
  .resizer {
    opacity: 0;
  }

  *:hover > .drag,
  *:hover > .resizer {
    opacity: 1;
  }
}
