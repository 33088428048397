div.ReactTable {
  .-loading.-active {
    z-index: 0;
  }
  border: none;
  .rt-noData {
    top: 100px;
  }
  .rt-noData:not(:empty) {
    top: 100px;
  }
  .rt-noData:empty {
    display: none;
  }
  .rt-tbody {
    min-height: 80px !important;
    border-top: solid 1px rgba(0, 0, 0, 0.05);
  }
  .rt-thead {
    font-size: 13px;
    color: #fff;
    line-height: 1.4;
    background-color: #2a7fff;
    padding: 12px 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    div.rt-th {
      font-weight: 400;
      &:first-child {
        padding-left: 20px;
      }
      text-align: left;
      border-right: none;
      &.-sort-asc,
      &.-sort-desc {
        box-shadow: none;
      }
    }
    .rt-resizable-header {
      .sort-icons {
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px;
        .fas {
          display: block;
          font-size: 14px;
          line-height: 7px;
          @media (max-width: 740px) {
            font-size: 25px;
            line-height: 12px;
          }
        }
      }
      &.-sort-asc {
        .sort-icons .fa-caret-down {
          color: #a9a9a9;
        }
      }
      &.-sort-desc {
        .sort-icons .fa-caret-up {
          color: #a9a9a9;
        }
      }
    }
  }
  .rt-tr-group {
    // padding: 12px 0px;
    -webkit-box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.122);
    box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.122);
    position: relative;
    color: #333;
    cursor: pointer;
    font-size: 13px;
    .rt-tr {
      padding: 12px 0px;
    }
    a {
      text-decoration: none;
      color: #333;
    }
    &:hover {
      background: #f9f9f9;
      box-shadow:
        inset 1px 0 0 #dadce0,
        inset -1px 0 0 #dadce0,
        0 1px 2px 0 rgba(60, 64, 67, 0.3),
        0 1px 3px 1px rgba(60, 64, 67, 0.15);
      box-shadow: 0px 2px 5px 0px #dadce0;
      color: #333;
    }
  }
}

.stripe-form {
  > span {
    display: none;
  }

  #payment-form {
    width: 90%;
    margin: 15px auto;
    label {
      width: 22%;
      display: inline-block;
      color: #484b52;
      font-size: 23px;
      line-height: 29px;
      font-family: "mosk-bold", sans-serif;
      @media (max-width: 375px) {
        font-size: 18px;
        line-height: 26px;
      }
    }

    #email {
      color: #484b52;
      font-size: 23px;
      line-height: 25px;
      font-family: "mosk-medium", sans-serif;
      overflow: hidden;
      @media (max-width: 375px) {
        font-size: 18px;
      }
    }

    #email,
    #card-cvv,
    #card-number,
    #card-expiry {
      width: 78%;
      display: inline-block;
      vertical-align: bottom;
    }

    #card-cvv,
    #card-expiry {
      width: 26%;
    }

    #card-expiry {
      margin-right: 10px;
    }

    #card-errors {
      padding: 10px 0;
      color: #eb1c26;
      font-size: 18px;
      font-family: "mosk-medium", sans-serif;
      line-height: 20px;
      text-align: center;
      @media (max-width: 375px) {
        font-size: 15px;
      }
    }

    .form-row {
      padding: 20px 0;
      border-bottom: 1pt solid #bfbfbf;
    }

    .remember-me-btn {
      padding: 20px 0;
      label {
        width: 80%;
      }

      input {
        width: 50px;
        height: 30px;
        border: 1px solid #ccc;
        border-radius: 15px;
        outline: none;
        vertical-align: middle;
        position: relative;
        background: #ccc;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        @media (max-width: 375px) {
          width: 40px;
          height: 23px;
        }
        &:checked {
          border: 1px solid #ff5858;
          background: #ff5858;
        }

        &:after {
          content: "";
          width: 26px;
          height: 27px;
          border-radius: 20px;
          position: absolute;
          top: 0px;
          background-color: #fff;
          box-shadow: 0 1.5pt 1pt 0.7pt rgba(0, 0, 1, 0.3);
          @media (max-width: 375px) {
            width: 20px;
            height: 20px;
          }
        }

        &:checked {
          &:after {
            right: 0px;
          }
        }
        &:not(:checked) {
          &:after {
            left: 0px;
          }
        }
      }
    }
  }

  .show-form {
    display: block;
  }

  .hide-form {
    display: none;
  }

  .card-details {
    width: 90%;
    margin: 15px auto;
    label {
      color: #484b52;
      font-size: 18pt;
      font-family: "mosk-thin", sans-serif;
      @media (max-width: 375px) {
        font-size: 16pt;
      }
    }
  }

  h2 {
    margin-bottom: 15px;
    color: #484b52;
    font-size: 25pt;
    font-family: "mosk-bold", sans-serif;
    @media (max-width: 375px) {
      font-size: 20px;
    }
  }

  .new-card {
    padding: 10px 25px;
    margin: 20px auto;
    display: block;
    border: 1px solid #ff5858;
    border-radius: 10px;
    background: #fff;
    color: #ff5858;
    font-size: 18pt;
    font-family: "mosk-bold", sans-serif;
  }

  .back-btn {
    position: absolute;
  }

  .fixed-cta {
    &:disabled {
      background-image: none;
      background-color: #ccc;
    }
  }
}

.custom-table {
  &.email-table {
    th,
    td {
      &:first-child {
        width: 5%;
      }
      &:nth-child(2) {
        width: 20%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &:nth-child(3) {
        width: 30%;
        max-width: 280px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &:nth-child(4) {
        width: 40%;
        max-width: 380px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  th {
    color: #fff;
    background: linear-gradient(to bottom, #777 0%, #444 100%);
    &:first-child,
    &:nth-child(4),
    &:nth-child(5) {
      width: 20%;
    }
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(6) {
      width: 10%;
    }
  }
  td {
    border: 1px solid #ccc;
    padding: 8px;
  }
}
