.dotLoader__variant-v1 span {
  position: relative;
  display: inline-block;
  margin: 0 2px;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: #2b7ef6;
  animation: v1 0.7s infinite linear;
}
.dotLoader__variant-v1 span:nth-child(2) {
  animation-delay: 0.2s;
}
.dotLoader__variant-v1 span:nth-child(3) {
  animation-delay: 0.4s;
}
.dotLoader__variant-v1 span:nth-child(4) {
  animation-delay: 1.9s;
}
@keyframes v1 {
  20% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px);
  }
}
