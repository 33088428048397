/* bootstrap variables and class overrides */
.card {
  --bs-card-cap-padding-y: 0.75rem;
  --bs-card-cap-padding-x: 1.25rem;
}

#email_body thead,
#email_body tbody,
#email_body tfoot,
#email_body tr,
#email_body td,
#email_body th,
.notifications-list thead,
.notifications-list tbody,
.notifications-list tfoot,
.notifications-list tr,
.notifications-list td,
.notifications-list th {
  border-width: inherit;
}

.reset-table-style tr,
.reset-table-style td,
.reset-table-style th {
  border: none !important;
  box-shadow: none !important;
  padding: 0;
  background-color: transparent !important;
}

.btn {
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.dropdown-menu {
  --bs-dropdown-border-radius: var(--bs-border-radius-sm);
}

.accordion {
  --bs-accordion-btn-padding-y: 0.75rem;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #28a745;
  --bs-btn-border-color: #28a745;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1e7e34;
  --bs-btn-hover-border-color: #1c7430;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1e7e34;
  --bs-btn-active-border-color: #1c7430;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #28a745;
  --bs-btn-disabled-border-color: #28a745;
}

/* Dragging Classes */
.custom-drag-image {
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 4px;
  padding: 4px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  max-width: 500px;
  word-wrap: break-word;
  overflow: hidden;
  white-space: nowrap;
}

.custom-drag-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.dragging {
  opacity: 0.7;
}

.rt-draggable-container .ReactTable .rt-thead .rt-th {
  display: initial !important;
}

/* cursor classes */
.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: unset;
}

.cursour-not-allowed {
  cursor: not-allowed;
}

.context-menu {
  cursor: context-menu;
}

.pointer-event-none {
  pointer-events: none;
}

/* background color classes */
.bg-dark-grey {
  background: #babebf !important;
}

.bg-light-grey {
  background: #f9fafb;
}

.bg-white {
  background: #fff;
}

.hover-light-grey:hover {
  background: #f9fafb !important;
}

.bg-light-grey {
  background: #f9fafb !important;
}

.bg-option-selected-grey {
  background-color: #eee;
}

.bg-primary-gradient {
  background: rgba(73, 155, 234, 1) !important;
}

.bg-secondary-gradient {
  background: rgb(4, 172, 67) !important;
}

/* text color classes */
.text-transparent {
  color: transparent;
}

.text-grey {
  color: #808080 !important;
}

.text-3F6289 {
  color: #3f6289;
}

.text-a9a9a9 {
  color: #a9a9a9;
}

.text-link {
  color: #2a7fff !important;
}

.text-link-grey {
  border-bottom: 1px solid transparent;
  transition: border-bottom 0.3s;
  padding: 0;
  margin: 0;
}
.text-link-grey:hover {
  border-bottom: 1px solid #007bff !important;
  padding: 0;
  margin: 0;
}

.text-cf-icons {
  color: #007bff !important;
}

.text-link:hover {
  text-decoration: underline;
}

.t-link:hover {
  color: #2a7fff !important;
}

.t-link-black:hover {
  text-decoration: underline;
  color: #0056b3;
  cursor: pointer;
}

.t-red {
  color: red;
}

.t-black {
  color: black;
}

.color-white {
  color: #fff;
}

.color-blue {
  color: #2a7fff;
}

.color-green {
  color: #28a745;
}

/* font-size classes */
.t-5 {
  font-size: 5px !important;
}

.t-6 {
  font-size: 6px !important;
}

.t-7 {
  font-size: 7px !important;
}

.t-8 {
  font-size: 8px !important;
}

.t-9 {
  font-size: 9px !important;
}

.t-10 {
  font-size: 10px !important;
}

.t-11 {
  font-size: 11px !important;
}

.t-12 {
  font-size: 12px !important;
}

.t-13 {
  font-size: 13px !important;
}

.t-14 {
  font-size: 14px !important;
}

.t-15 {
  font-size: 15px !important;
}

.t-16 {
  font-size: 16px !important;
}

.t-18 {
  font-size: 18px !important;
}

.t-20 {
  font-size: 20px !important;
}

.t-22 {
  font-size: 22px !important;
}

.t-28 {
  font-size: 28px !important;
}

/* dropdown button classes */
.dropdown-wrap .form-group {
  display: inline-block;
  width: 100%;
}

.dropdown-menu.show {
  z-index: 100;
}
.dropdown-wrap .dropdown-menu {
  z-index: 5;
}
.dropdown-list-wrap {
  padding: 0;
  max-height: 50vh;
  overflow-y: auto;
  min-width: 13rem;
}

div[id*="license-text"] {
  display: none !important;
}

.hide-graph-title .custchattabhead {
  display: none;
}

.custom-select {
  background-color: #fff;
  height: 39px;
  width: 100%;
}

.custom-select-thin {
  background-color: #fff;
  height: 36px;
  font-size: 12px;
  font-family: Poppins, sans-serif !important;
  width: 100%;
  border-radius: 0px;
}

select:not([multiple]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: right 50%;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
  padding: 0.5em;
  padding-right: 1.5em;
}

#myThinSelect {
  border-radius: 0;
}

.ck-editor__editable_inline {
  min-height: 250px;
}

/* for ckeditor table header and rows text alignment */
#email-wrapper .ck-editor__editable :first-child .ck-editor__nested-editable,
#email-wrapper
  .ck-editor__editable
  tr:not(:first-child)
  .ck-editor__nested-editable {
  text-align: center;
}

#email-wrapper
  .ck-editor__editable
  tbody
  tr
  .ck-editor__nested-editable:nth-child(5) {
  text-align: right;
}

/*modals*/
.custom-modal {
  width: 100vw;
  height: 100vh;
}

/* snackbar */
.snackbar-wrapper {
  border-radius: 4px;
  width: 30vw;
  height: 40px;
  padding: 8px;
  margin-top: 72px;
  color: white;
}

div.modal.show .modal-dialog {
  top: 45%;
  margin: 0 auto;
  transform: translate(0, -52%);
}

.modal-body h3 {
  text-align: center;
  font-size: 25px;
}

.modal-header .close {
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
}
.modal-header {
  justify-content: space-between;
}
.close {
  color: #000;
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  opacity: 0.5;
  text-shadow: 0 1px 0 #fff;
}

a {
  text-decoration: none;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

.snackbar-msg {
  margin-left: 4px;
}

.snackbar-closeicon {
  margin-top: 4px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/*thumbnails*/
.overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

/* fonts */
.t-global {
  font-family: "Lato", sans-serif !important;
}

/*Cards*/
.sticky-below-header {
  top: 75px;
  position: sticky;
  z-index: 1;
}

.card-header {
  top: 68px;
  position: sticky;
  z-index: 1;
}

.modal-accordion .card-header {
  top: 0;
}

.non-sticky-header-container .card-header {
  top: unset;
  position: unset;
  z-index: unset;
}

/* Form components*/
input[type="text"] ::placeholder {
  font-family: Poppins, sans-serif;
}

.input-bottom-border {
  background: transparent;
  border-color: transparent !important;
  border-bottom-color: silver !important;
  outline: none;
}

input.md-checkbox {
  width: 20px;
  height: 20px;
}

input.sm-checkbox {
  width: 17px;
  height: 17px;
}

input.daterangeinput {
  border: none !important;
  width: 89%;
  min-width: 164px;
}

.daterangepicker:not(.single).show-calendar {
  width: 655px !important;
}

input.daterangeinput:focus {
  outline: none;
}

.react-datepicker-component .react-datepicker-input {
  height: 38px;
}

.react-date-picker__calendar.react-date-picker__calendar--open {
  z-index: 2;
}

.form-error {
  color: #f30909;
  font-size: 12px;
}

.donut-legends {
  width: 40%;
}

.total-ar-customer.w-50 {
  width: 80% !important;
}

.form-control.input-error {
  border: 1px solid #f30909;
}

.static_height .form-group {
  height: 60px !important;
}

/*Border*/
.border-none {
  border: none;
}

.outline-none {
  outline: none !important;
}

/*Graphs*/
.donutchart-legend-dot {
  color: #fff;
  border-radius: 50px;
  width: 18px;
  height: 18px;
  display: inline-block;
  margin: 0px 3px -5px 0px;
}

.donutchart-legend-square {
  color: #fff;
  width: 16px;
  height: 16px;
  display: inline-block;
  margin: 0px 3px -5px 0px;
}

/*font awesome*/
i.cfa-rounded-circle {
  display: inline-flex;
  border-radius: 60px;
  box-shadow: 3px 0 10px 0 rgba(183, 192, 206, 0.41);
  padding: 8px 8px;
  margin-bottom: 10px;
  height: 36px;
  width: 36px;
  margin-right: 8px;
  background-color: white;
  align-items: center;
  justify-content: center;
}

/*widths & height*/
.minht-300 {
  min-height: 300px !important;
}

.custom-modal-width-90 {
  margin: auto;
  width: 90% !important;
  max-width: none !important;
}

.video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.middle-panel-height {
  height: calc(100vh - 150px);
}

.video-player video {
  display: block;
  width: 100%;
  height: 100%;
}

/* width classes */
.width-auto {
  width: auto !important;
}

.width-95 {
  width: 95%;
}

.width-94px {
  width: 94px;
}

.minwidth-94px {
  min-width: 94px;
}

.width-120px {
  width: 120px;
}

.width-150px {
  width: 150px;
}

.width-200px {
  width: 200px !important;
}
.min-width-200 {
  min-width: 210px;
}

.w-240px {
  width: 240px;
}

.w-300px {
  width: 300px;
}

.width-500px {
  width: 500px;
}

.width-750px {
  width: 750px;
}

.w-40vw {
  width: 40vw !important;
}

.w-48 {
  width: 48% !important;
}

.w-50 {
  width: 50% !important;
}

.w-52 {
  width: 52% !important;
}

.w-40 {
  width: 40% !important;
}

.w-33 {
  width: 33.33%;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-45 {
  width: 45% !important;
}

.w-10 {
  width: 10% !important;
}

.w-12 {
  width: 12% !important;
}

.w-14 {
  width: 14% !important;
}

.w-16 {
  width: 16% !important;
}

.w-18 {
  width: 18% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.max-width-300px {
  max-width: 300px !important;
}

/* Height classes */

.h-60px {
  height: 60px !important;
}

.h-40px {
  height: 40px !important;
}

.h-38px {
  height: 38px !important;
}

.h-35px {
  height: 35px !important;
}

.h-32px {
  height: 32px !important;
}

.h-max-60px {
  max-height: 60px !important;
}

.h-85vh {
  height: 85vh;
}

.height-400px {
  max-height: calc(100vh - 400px);
}

/* margin classes*/
.mt-128px {
  margin-top: 128px !important;
}

.mt-80px {
  margin-top: 80px;
}

.mt-90px {
  margin-top: 90px;
}

.mt-60px {
  margin-top: 60px !important;
}

.mt-3px {
  margin-top: 3px !important;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.ms-10px {
  margin-left: 10px;
}

.ms-12px {
  margin-left: 12px;
}

.ms-15px {
  margin-left: 15px;
}

.ms-20px {
  margin-left: 20px;
}

.ms-30px {
  margin-left: 30px;
}

.ms-40px {
  margin-left: 40px;
}

.ms-50px {
  margin-left: 50px;
}

.ms-65px {
  margin-left: 65px;
}

.me-6px {
  margin-right: 6px;
}

.ms-6px {
  margin-left: 6px;
}

.mt-6px {
  margin-top: 6px;
}
.mt-4-5 {
  margin-top: 38px;
}

.mb-6px {
  margin-bottom: 6px;
}
.mb-15 {
  margin-bottom: 15px;
}

.mb-minus-1 {
  margin-bottom: -1px;
}

/* overflow classes */
.overflow-x-auto {
  overflow-x: auto;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-unset {
  overflow: unset !important;
}

/* Position offset classes */
.top-50 {
  top: 50%;
}
.top-0 {
  top: 0;
}

div.top-180px {
  top: 172px;
}

.bottom-50 {
  bottom: 50%;
}
.bottom-0 {
  bottom: 0;
}

.left-50 {
  left: 50%;
}
.left-0 {
  left: 0;
}

.right-50 {
  right: 50%;
}
.right-0 {
  right: 0;
}

.zIndex-1 {
  z-index: auto !important;
  position: static !important;
}

/*paddings*/
.ps-30px {
  padding-left: 30px !important;
}
.p-16px {
  padding: 0px 16px;
}

.btn-primary {
  background: linear-gradient(
    to bottom,
    rgba(73, 155, 234, 1) 0%,
    rgba(32, 124, 229, 1) 100%
  ) !important;
}

.btn-silver {
  background: linear-gradient(
    to bottom,
    rgb(255, 255, 255) 0%,
    rgb(196, 196, 196) 100%
  ) !important;
}

.btn-hover-opacity {
  opacity: 0.8;
}

.btn-hover-opacity:hover {
  opacity: 1;
}

.nav.nav-tabs .active {
  background: linear-gradient(to bottom, #777777 0%, #444444 100%) !important;
  color: #fff !important;
}

/*table*/
.rt-thead {
  background: linear-gradient(to bottom, #777777 0%, #444444 100%) !important;
  color: white !important;
  border-radius: 5px !important;
  border-color: #888888;
}

.rt-resizable-header-content {
  cursor: pointer;
}

div.ReactTable .rt-resizer {
  z-index: 2;
}

.ReactTable .rt-tbody {
  min-height: 80px !important;
  overflow: hidden !important;
}

.ReactTable .rt-th.-hidden,
.ReactTable .rt-td.-hidden {
  display: none;
}

.dark-gradient-cell {
  background: linear-gradient(to bottom, #777777 0%, #444444 100%) !important;
  color: white;
  font-weight: normal;
  min-width: 120px;
}

/*icons*/
.onlyicon {
  font-size: 18px;
  padding: 15px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  width: 100%;
}

.cashinflowclick,
.cashoutflowclick,
.nonoperatflowclick {
  cursor: pointer;
  color: #2a7fff;
  border: 1px solid #2a7fff;
  padding: 5px;
  margin-left: 10px;
}

.accordion__button td .cashinflowclick {
  display: none;
}

.accordion__button[aria-expanded="true"] td .cashinflowclick {
  display: inline-block;
}

.table-container {
  position: relative;
}

.table-container .loader {
  text-align: center;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

.paynewdetsec {
  border-radius: 5px;
  margin-bottom: 30px;
}

.paynewdetsec.non-splitted .col-md-2 {
  padding-bottom: 20px;
}

.paynewdetsec label {
  font-weight: 500;
}

.paynewdetsec .subbtn {
  font-size: 14px;
  border: none;
}

.paynewdetsec .subbtn {
  box-shadow: none;
  border: none;
  outline: none;
}

.linesTableData {
  width: 240px;
}

.paynewdetsectran {
  border-radius: 5px;
}

.paynewdetsectran table {
  border-collapse: collapse;
  width: 100%;
}

.paynewdetsectran td,
th {
  text-align: left;
  padding: 8px;
}

.paynewdetsectran th {
  background: linear-gradient(to bottom, #777777 0%, #444444 100%) !important;
  color: #fff;
  font-weight: normal !important;
}

.paynewdetsectran tr:nth-child(even) {
  background-color: #ececec;
}

.paynewdetsectran .titletranshist {
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 15px;
}

.divider {
  border-top: 1px solid #ddd;
  padding: 15px 0px;
  margin-top: 10px;
}

.total-amount-wrapper {
  padding: 5px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  position: absolute;
  right: 20px;
  background-color: #fafafa;
}

.total-amount-wrapper.in-col {
  right: -22px !important;
  top: -23px !important;
}

.total-amount-wrapper.right-0 {
  right: 0px !important;
}

.customer-aging-chart .recharts-default-legend,
.vendor-aging-chart .recharts-default-legend {
  text-align: left !important;
}

.customer-aging-chart .recharts-default-legend li,
.vendor-aging-chart .recharts-default-legend li {
  width: 45%;
}

.customer-aging-chart .recharts-legend-wrapper {
  bottom: -20px !important;
}

.small-legend-size .recharts-legend-item {
  font-size: 12px;
  line-height: 12px;
}

.payIq-container {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.vendor-payIq-container {
  width: 91%;
}

.payIq-container .mt-2 span.btn {
  margin-bottom: 10px;
}

.total-amount-wrapper.right-0.wrap-amount-flex {
  position: static;
  display: flex;
}

.custom-table-header .btn {
  margin: 10px 5px 0px 0px;
  padding: 5px;
}

.custom-table-header .custom-button {
  background: #2a7fff !important;
  color: white;
  font-size: 14px;
  padding: 4px;
}

.custom-table-header .custom-button .disabled {
  background: #ccc !important;
}

.inputWithIcon input::placeholder {
  color: #2a7fff;
}

.inputWithIcon {
  position: relative;
}

.inputWithIcon input {
  height: 35px;
}

.inputWithIcon i {
  position: absolute;
  right: 0;
  top: 28px;
  padding: 8px 8px;
  color: #2a7fff;
  transition: 0.3s;
  font-size: 18px;
  height: 35px;
}

.autocomplete.inputWithIcon i {
  top: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}

.floatedPayIQ {
  position: sticky;
  top: 190px;
  z-index: 1;
  background: rgba(255, 255, 255, 0.7);
}

.floatedTask {
  height: 58px;
  position: sticky;
  top: 0;
  z-index: 1;
  background: rgba(255, 255, 255, 0.7);
}

.floatedPayIQ2 {
  width: 100% !important;
  padding-bottom: 7px;
  position: -webkit-sticky;
  position: sticky !important;
  top: 75px;
  z-index: 1;
  background: rgba(255, 255, 255, 0.99);
}

.PayIQ3 {
  position: relative;
  display: flex;
}

.floatedPayIQ3 {
  width: 100% !important;
  display: flex;
  position: -webkit-sticky;
  position: sticky !important;
  top: 65px;
  z-index: 1;
  background: rgba(255, 255, 255, 0.99);
}

.floatedPayIQ3 + .paytable,
.floatedPayIQ3 + .paytable .mt-2 {
  margin-top: 0 !important;
}

.hidden {
  display: none !important;
}

.hide-scroll::-webkit-scrollbar {
  width: 0px !important;
  /* Remove scrollbar space */
  background: transparent !important;
  /* Optional: just make scrollbar invisible */
}

.hide-scroll {
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.cs-sticky-scroll {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 98.5%;
  height: 20px;
  background: #f1f1f1;
  overflow-x: auto;
  z-index: 1000;
}

.cs-scrollbar {
  height: 1px;
}

.graph-loader {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* remove x from input edge browser */
input::-ms-clear,
input::-ms-reveal {
  display: none;
}

.show-ellipses {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hideBorder:focus {
  border: none;
  outline: none;
}

.white-space-nowrap {
  white-space: nowrap;
}

/*rc-datepicker*/
.react-datepicker .react-datepicker-container .react-datepicker-top {
  background: white;
  color: black;
}

@media (max-width: 1200px) {
  div.full-width {
    flex: 1;
    max-width: 100%;
  }
}

/*stick top*/
.nav-stick-top nav,
.nav-stick-top .nav {
  position: sticky;
  top: 68px;
  z-index: 100;
  width: 100%;
  background: #fff;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 10px;
}

.nav-stick {
  position: sticky;
  z-index: 0;
}

/*react-bootstrap*/
.no-arrow.dropdown-toggle:after {
  content: none !important;
}

/*borders*/
.border-shadow-primary {
  box-shadow: 0px 0px 5px 0px #2a7fff;
}

.border-shadow-danger {
  box-shadow: 0px 0px 4px 0px #f30909;
}

.border-grey {
  border: 1px solid #ced4da;
}

/*animations*/
.flash-border-primary {
  animation: blink 10s;
  animation-iteration-count: 1;
}

@keyframes blink {
  100% {
    box-shadow: 0px 0px 8px #2a7fff;
  }
}

@keyframes fading-anim {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 0.6;
  }

  100% {
    opacity: 0.2;
  }
}

.loading-anim {
  color: grey !important;
  animation: fading-anim 1.2s ease infinite;
}

.hover-blue:hover {
  background-color: rgb(222, 235, 255) !important;
}

button.edit-workflow-btn {
  margin-left: 5px;
  padding: 1px 3px;
}

.graph-wrapper .custchattab1,
.dashboard-wrapper .custchattab1 {
  display: none;
}

.logo-file-element {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.circular-loader {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #2b7ef6;
  width: 60px;
  margin: 0 auto;
  height: 60px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.profile-pic {
  position: relative;
  display: inline-block;
}

.profile-pic:hover .remove-logo-btn {
  display: block;
}

.open-profilepic-menu {
  position: absolute;
  transform: translate3d(-172px, 5px, 0px);
  top: 0px;
  left: 0px;
  will-change: transform;
}

.remove-logo-btn {
  padding-top: 7px;
  padding-right: 7px;
  position: absolute;
  right: 0;
  top: -20px;
  display: none;
}

.line {
  width: 120px;
  height: 3px;
  background-color: #ccc;
  display: inline-block;
  vertical-align: middle;
}

label {
  margin-bottom: 0.5rem;
}

.payment-option-radio label {
  width: 200px;
  text-align: center;
  border: 3px solid #ccc;
  color: #958e8e;
  padding: 5px 10px;
  border-radius: 5px;
}

.payment-option-radio.payment-saved-radio label {
  width: 223px;
}

.payment-saved-radio .account-block:nth-child(2n) {
  margin-left: 10px;
}

.card-radio label {
  width: 150px;
}

.payment-option-radio label.active,
.payment-option-radio label:hover {
  border-color: #28a745;
  color: #28a745;
  transition: 0.3s;
}

.payment-option-radio input {
  width: 0;
  height: 0;
  opacity: 0;
}

.btn.color-grey {
  color: #ccc;
  outline: none;
}

.btn.color-grey:focus,
.btn.p-0:focus,
.btn.color-green1:focus {
  box-shadow: none;
  outline: none;
}

.btn.color-green1 {
  color: #28a745;
  outline: none;
}

.btn.border-radius-circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.btn.payment-btn {
  margin-top: -5px;
  font-weight: 700;
}

/* breadcrumb classes */
.hirerchy-breadcrumb .breadcrumb {
  padding: 10px;
  margin-bottom: 0;
  background-color: transparent;
}

.hirerchy-breadcrumb
  .breadcrumb-item.arrow-separator
  + .breadcrumb-item.arrow-separator::before {
  font-family: "Font Awesome 5 Free";
  content: "\f054";
  display: inline-block;
  vertical-align: middle;
  font-weight: 900;
  font-size: 12px;
}

.hirerchy-breadcrumb
  .breadcrumb-item.arrow-separator
  + .breadcrumb-item.arrow-separator:not(.no-children).active::before {
  font-family: "Font Awesome 5 Free";
  content: "\f078";
  color: #333;
}

.hirerchy-breadcrumb .active a {
  color: #333;
}

.hirerchy-breadcrumb .show-ellipses {
  list-style-type: none;
}

.hirerchy-breadcrumb li:before {
  font-family: "Font Awesome 5 Free";
  content: "\f054";
  display: inline-block;
  padding-right: 10px;
  vertical-align: middle;
  font-weight: 900;
  font-size: 10px;
}

.hirerchy-breadcrumb li.active:before {
  font-family: "Font Awesome 5 Free";
  content: "\f078";
}

.hirerchy-breadcrumb li.active + li {
  display: none;
}

.ReactTable .expand-row {
  position: relative;
}

.ReactTable .expand-row .expandable {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.beta {
  background-color: orange;
  padding: 0 5px;
  font-weight: 700;
  border-radius: 5px;
  color: white;
  font-size: 11px;
  transform: translate(3px, -6px);
  display: inline-block;
}

.card-container {
  display: flex;
  flex-direction: column;
  padding: 25px;
  /* cursor: pointer; */
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;
}

.fw {
  font-weight: 700;
  color: #2a7fff;
  text-align: center;
}

.pyramid-green {
  width: 5px;
  border-left: 8px dotted transparent;
  border-right: 8px solid transparent;
  border-bottom: 15px solid green;
  position: absolute;
}

.pyramid-red {
  width: 5px;
  border-left: 8px dotted transparent;
  border-right: 8px solid transparent;
  border-top: 15px solid red;
  position: absolute;
}

.pyramid-green-activity {
  width: 5px;
  border-left: 8px dotted transparent;
  border-right: 8px solid transparent;
  border-bottom: 15px solid green;
}

.pyramid-red-activity {
  width: 5px;
  border-left: 8px dotted transparent;
  border-right: 8px solid transparent;
  border-top: 15px solid red;
}

.activity-card-bg {
  margin: 30px 60px;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;
}

.activity-card-bg2 {
  margin: 15px 50px;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;
}

.activity-card-bg:hover,
.activity-card-bg2:hover {
  transform: scale(1.05);
}

.f {
  font-weight: 700;
}

.btn-red {
  background: linear-gradient(to bottom, #fc0d1b 0%, #da020f 100%) !important;
}

.btn-red:focus {
  outline: 1px !important;
}

/* cashflow update table css */
.excel-table {
  margin: 0 auto;
  border-collapse: unset;
  border-spacing: 0;
  border-style: hidden;
}

.excel-table td > input {
  font-size: 13px;
  border: none;
  padding: 2px;
  outline: none;
  height: 20px;
}

.excel-table tr:nth-child(2n),
.excel-table tr:nth-child(2n) input {
  background-color: #f0f0f0 !important;
}

.excel-table input:focus {
  box-shadow: none;
}

.excel-table td {
  border: 1px solid #d7d7d7;
}

.excel-table td input.form-control {
  font-weight: 500;
}

.display-scroll-macos {
  -webkit-overflow-scrolling: auto !important;
}

.excel-table th {
  padding: 3px;
  color: #ffffff;
  text-align: center;
  background-image: linear-gradient(#767676, #444444);
}

.excel-table.expand-cols.sf th:nth-child(3) ~ th,
.excel-table.expand-cols.sf td:nth-child(3) ~ td {
  display: none;
}

.excel-table.expand-cols:not(.sf) th:nth-child(2) ~ th,
.excel-table.expand-cols:not(.sf) td:nth-child(2) ~ td {
  display: none;
}

/* .excel-table .dropdown-menu {
  left: -82px;
} */

.table-expand-icon {
  right: -12px;
  top: 17px;
  line-height: 15px;
  border-radius: 50%;
}

.pdf-iframe {
  width: 100%;
  height: 70vh;
  display: block;
}

.loader-small {
  width: 20px;
  height: 20px;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #2b7ef6;
  margin: 0 auto;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
  vertical-align: middle;
}

.card-hover-shadow:hover {
  box-shadow: 0px 0px 5px 0 rgb(160 160 160);
}

/* Dynamic Width */
.flex-grow-100 {
  flex-grow: 100;
}

.confirmation-box {
  z-index: 10000;
  position: fixed;
  width: 350px !important;
  left: 40.25% !important;
}

.new-scenarios-popup {
  z-index: 10000;
  position: fixed;
  width: 420px !important;
  left: 38.5% !important;
}

.grey-card {
  background-color: #f9f9f9;
  padding: 6px;
}

.checkbox-text {
  width: 40px;
  height: 40px;
  font-size: 25px;
  line-height: 30px;
  padding: 5px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.checkbox-text.selected {
  background-color: #2a7fff;
  color: #fff;
}

.category-picker-graph {
  width: 100%;
  z-index: 100;
  height: 80%;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  overflow-x: auto;
  text-align: left;
}

.sticky-buttons {
  padding: 5px;
  bottom: 0;
  position: sticky;
  background-color: #fff;
}

.bottom-0 .sticky-buttons,
.sticky-buttons.bottom-0 {
  bottom: 0;
}

.line-height-16 {
  line-height: 16px;
}

.expanded-graph-modal .modal-content {
  width: 90vw !important;
}

.expanded-graph-modal .large-modal {
  padding: 0 !important;
  margin: 0 5vw !important;
}

.text-underline {
  text-decoration: underline;
}

label.add-card-button {
  border: 3px dotted #ccc;
}

label.add-card-button:hover {
  border: 3px dotted #28a745;
  color: #28a745;
}

.accounts:hover {
  background-color: #f3f4f5;
}

.bankCard {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid transparent;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}
.bankCard:not(.no-hover):hover,
.bankCard.selected {
  background-color: rgb(0 123 255 / 20%);
  box-shadow: 0px 2px 5px 0px #dadce0;
  border: 1px solid #ccc;
}

.bankCard:not(.no-hover):hover {
  opacity: 0.7;
}

.payandreview {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  position: sticky;
  padding: 15px;
  margin-left: 6px;
}

.border-red {
  border: 1px solid red;
}

.tooltip-inline {
  text-align: left !important;
}

.tooltip-inline > div {
  margin: 4px 0;
}

.small-text-input-box {
  text-align: right;
  width: 45px;
  height: 26px;
  margin-bottom: 2px;
}

.lineItem {
  max-width: 250px;
  overflow-wrap: break-word !important;
}

.accordion-folders .accordion__button[aria-expanded="true"] .fa-plus,
.accordion-folders .accordion__button[aria-expanded="false"] .fa-minus {
  display: none;
}

.accordion-folders .accordion__button[aria-expanded="true"] .fa-minus {
  display: inline-block;
}

.no-arrow-accordion .accordion-button {
  padding: 0;
  border: none;
  background-color: transparent;
}

button.accordion-button::after {
  display: none;
}
.no-arrow-accordion .accordion-item {
  background-color: transparent;
  border: none;
}

.accordion-item button.accordion-button {
  background-color: transparent;
  outline: none;
  box-shadow: none;
}

.accordion-item .accordion-header {
  padding: 0;
}
.accordion-item {
  border: none;
}

/* Notifications */
.notification-count {
  color: #fff;
  position: absolute;
  margin: 10px 0 0 18px;
  z-index: 10;
  background: #2b7ef6;
  padding: 1px;
  border-radius: 6px;
  border: 1px solid #fff;
  width: 40px;
  height: 20px;
  font-size: 10px;
  top: 0;
  text-align: center;
}

.notification-tray::before {
  right: 80px;
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  position: absolute;
  display: inline-block;
  content: "";
}

.notification-tray::after {
  right: 80px;
  top: -7px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  position: absolute;
  display: inline-block;
  content: "";
}

.notification-bell {
  font-size: 27px;
  margin: 18px 25px 0px 0;
  background: linear-gradient(#2e2e2e, #545353);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.support-icon {
  font-size: 28px;
  margin-top: 10px;
  margin-right: 25px;
  padding: 0;
}

.notification-bell:hover,
.support-icon:hover {
  animation: shake 0.4s;
}

@keyframes shake {
  0% {
    transform: rotate(0);
  }

  15% {
    transform: rotate(5deg);
  }

  30% {
    transform: rotate(-5deg);
  }

  45% {
    transform: rotate(4deg);
  }

  60% {
    transform: rotate(-4deg);
  }

  75% {
    transform: rotate(2deg);
  }

  85% {
    transform: rotate(-2deg);
  }

  92% {
    transform: rotate(1deg);
  }

  100% {
    transform: rotate(0);
  }
}

.dropdown-menu.notification-tray {
  width: 400px;
  right: 11px;
  top: 57px;
  box-shadow: -1px 1px 2px #dfdcdc;
}

.notifications-list {
  max-height: 500px;
  overflow-y: auto;
}

.notifications-list .notification-item:last-child {
  border-bottom: none !important;
}

.notification-item:hover {
  background-color: #ececec;
}

.notifications-list p {
  margin-bottom: 0;
}

.highlighted-text {
  background-color: #a3c8f080;
  font-weight: bold;
  border-radius: 3px;
  padding: 3px 6px;
}

.bulk-action-tooltip {
  height: 20px;
  width: 30px;
}

.sidebarPayment {
  position: relative;
  left: 165px;
}

.date-picker-input-readonly
  .react-datepicker-component
  > .react-datepicker-input
  > input {
  pointer-events: none;
}

.removeDate {
  color: rgb(203, 200, 200);
  border-radius: 2px;
  font-weight: bolder;
  margin-left: 2px;
}

.removeDate:hover {
  color: black;
  transition: all 0.3s ease-in-out;
}
.border-top-none {
  border-top: none !important;
}

.filelist-wrapper {
  height: 300px;
  overflow-y: auto;
}

.filelist-wrapper .card {
  display: block;
  height: 150px;
}

.customer_paid_col {
  background-color: #ececec;
}
.invoiceDownload {
  opacity: 0.5;
}
.downloadInvoiceIcon {
  color: #328efc;
}

/* switcher */
div.checkbox.switcher label,
div.radio.switcher label {
  padding: 0;
}
div.checkbox.switcher label *,
div.radio.switcher label * {
  vertical-align: middle;
}
div.checkbox.switcher label input,
div.radio.switcher label input {
  display: none;
}
div.checkbox.switcher label input + span,
div.radio.switcher label input + span {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  width: 56px;
  height: 28px;
  background: #dbdada;
  border: 1px solid #eee;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}
div.checkbox.switcher label input + span small,
div.radio.switcher label input + span small {
  position: absolute;
  display: block;
  width: 50%;
  height: 100%;
  background: #fff;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  left: 0;
}
div.checkbox.switcher label input:checked + span,
div.radio.switcher label input:checked + span {
  background: #269bff;
  border-color: #269bff;
}
div.checkbox.switcher label input:disabled + span,
div.radio.switcher label input:disabled + span {
  background: #c7c2c2;
  border-color: #c7c2c2;
  cursor: not-allowed;
}
div.checkbox.switcher label input:disabled + span small,
div.radio.switcher label input:disabled + span small {
  background: #f5f1f1;
}
div.checkbox.switcher label input:checked + span small,
div.radio.switcher label input:checked + span small {
  left: 50%;
}

/* accept reject checkbox */
.checkbox-accept-reject {
  width: 22px;
  height: 22px;
  border-radius: 3px;
}

.checkbox-accept-reject.disabled {
  cursor: default;
  background-color: rgba(83, 83, 83, 0.5);
  color: rgb(84, 84, 84);
  border-color: rgba(118, 118, 118, 0.3);
}

.checkbox-accept-reject i {
  display: inline-block;
  vertical-align: top;
  padding-top: 4px;
}

.disabled_box {
  border-radius: 4px;
  background-color: #e9ecef;
  padding: 0.375rem 0.75rem;
  color: #495057;
  border: 1px solid #ced4da;
}

.disabledbutton {
  opacity: 0.4;
  pointer-events: none;
}

.confirm-password {
  position: relative;
}

.eye-password {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.nav-tabs .nav-link {
  outline: none;
}

.fa-equals {
  font-size: smaller;
  opacity: 0.6;
}

.form-control2 {
  font-size: medium;
  opacity: 1;
  margin: 6px 0;
}

.collapse_filter {
  /* border: 1px solid #c7cbd1;  */
  border-radius: 5px;
  background-color: white;
  position: absolute;
  z-index: 2;
  width: 100%;
  transition: all 0.25s linear;
  padding: 2px 0;
  box-shadow:
    rgba(0, 0, 0, 0.06) 2px 1px 2px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0.1px;
}

.collapse_filter > div:nth-child(2) {
  flex: 0.03;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s linear;
  border: 1px solid #c7cbd1;
  margin: 0 10px;
  padding: 2.8px 0;
  border-radius: 999px;
}

.collapse_filter > div:nth-child(2):hover {
  transform: scale(1.02);
}

.collapse_filter > div:nth-child(1) {
  display: flex;
  width: 100%;
  /* flex-wrap:wrap; */
  flex: 0.97;
  white-space: nowrap;
  overflow: hidden;
  transition: all 0.25s linear;
}

.filterChipHeader::-webkit-scrollbar {
  display: none;
}

.filterNExport {
  z-index: 4;
}

.filterChip {
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-size: small;
  border: 1px solid #c7cbd1;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  width: fit-content;
  padding: 0px 4px;
}

.filterChip:hover {
  background-color: #f5f8fa;
}

.disableClip {
  pointer-events: none;
}

.filterChip > .editChip {
  position: absolute;
  background-color: #f7f8f9;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.filterChip > div:nth-child(1) {
  color: #929ba7;
  cursor: pointer;
  font-size: 14px;
}

.filterChip > div:nth-child(2) {
  color: #7f8897;
  font-weight: 500;
}

.filterChip > div:nth-child(3) {
  font-weight: bold;
  opacity: 0.9;
  color: #257fe5;
  transition: all 0.15s linear;
  align-items: center;
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.filterChip > div:nth-child(3):hover {
  text-decoration: underline;
  cursor: pointer;
}

.filterChip > div:nth-child(4) {
  color: #7f8897;
  font-size: medium;
  cursor: pointer;
}

.wrapFilterChip {
  flex-wrap: wrap;
}

.noWrapFilterChip {
  flex-wrap: nowrap;
}

.dynamicFilterHeight {
  height: fit-content;
}

.staticFilterHeight {
  height: 35px;
}

.box {
  position: absolute;
  display: flex;
  background-color: rgb(255, 254, 254);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px;
  flex-direction: column;
  padding: 10px;
  margin: 12px 10px;
  font-weight: bold;
  border-radius: 5px;
  width: 15vw;
  z-index: 3;
  transition: all 0.2s linear;
  transform: scale(0);
}

.dateFilter {
  width: fit-content;
}

.box > i {
  opacity: 0.8;
}

.box.arrow-top::before {
  content: "";
  position: absolute;
  left: 50%;
  top: -15px;
  width: 0;
  transform: rotate(90deg);
  height: 0;
  border-top: 10px solid transparent;
  border-right: 12px solid rgb(246, 243, 243);
  border-bottom: 10px solid transparent;
}

.box > button {
  height: 32px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.transformChip1 {
  transform: scale(1);
}

@media (max-width: 900px) {
  .dropdown-menu.notification-tray {
    width: 95%;
  }
  .mobile-padding-top {
    margin-top: 80px;
  }
}

#eProtectiframe iframe {
  min-height: 300px;
}

g:focus,
path:focus {
  outline: none !important;
}

#alevate_cashapp .allocation-container .row > * {
  width: initial;
}
