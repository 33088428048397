.aiSupportBot-container {
  position: fixed;
  bottom: 20px;
  z-index: 1060;
  width: fit-content !important;
  right: 19px;
  border-radius: 50%;
  display: inline;
  padding: 4px !important;
}
/* chat window */
.aiSupportBot-continer__chatWindow {
  background-color: aliceblue;
}
.aiSupportBot__popper-paper {
  width: 350px;
  border-radius: 8px;
  overflow: hidden;
}
.aiSupportBot-continer__chatWindow__header {
  background-color: #2b7ef6;
  color: white;
}
/* message logs contianer */
.messageLogs__container {
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
  padding-top: 4px;
  margin-top: 4px;
}
.messageLogs__message {
  margin-bottom: 5px;
  padding: 3px 10px 4px 10px;
  border-radius: 8px;
  width: calc(100% - 55px);
}
.messageLogs__message p {
  margin: 0;
  padding: 0;
}
.messageLogs__message--system,
.messageLogs__message--assistant {
  text-align: left;
  align-self: start;
}
.messageLogs__message--user {
  text-align: right;
  align-self: flex-end;
  width: fit-content;
  max-width: calc(100% - 55px);
  background-color: rgba(0, 0, 0, 0.11);
}
