.phone-rotate {
  transform: rotate(90deg);
}

.flipCard {
  transform: rotateY(180deg);
}

/* Dialler */
.keypad_header {
  box-shadow: rgba(0, 0, 0, 0.05) 1px 1px 2px 0.5px;
  border-radius: 3px;
  padding: 5px 0;
  background-color: #fdfdfd;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 1s;
  transform-style: preserve-3d;
  height: 40em;
}
/* .keypad_header:hover {
    transform: rotateY(180deg);
  } */
.keypad {
  display: grid;
  list-style: none;
  padding: 0;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px 10px;
}
.keypad li {
  display: flex;
  justify-content: center;
}

.keypadinput {
  font-size: 24px;
  border-color: transparent;
  width: 100%;
  text-align: center;
  margin: 0.5em 2em;
}

.keypadinput::placeholder {
  font-size: 18px;
}

.keypadinput:focus {
  outline: none;
}

.country_code {
  background-color: #feefce;
  color: #d89d2e;
  padding: 5px 10px;
  width: fit-content;
  border-radius: 3px;
}
/* Dialler End*/

/* Keyboard */
.keypad-button {
  border: none;
  border-radius: 100%;
  background-color: #f4f4f6;
  color: #121c2d;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 0px 1px;
  width: 2.3em;
  height: 2.3em;
  font-size: 24px;
  line-height: 1;
  transition: all 0.2s linear;
}
.keypad-button:hover {
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 0px 1px inset;
}
.hideButton {
  border: none;
  background-color: #fdfdfd;
  box-shadow: none;
}
.keypad-button > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.keypad-button > div > div:nth-child(2) {
  font-size: large;
}
.noBorder {
  border: none;
  border-radius: 100%;
  font-size: 24px;
  line-height: 1;
  background-color: white;
  color: black;
  opacity: 0.8;
  transition: all 0.2s linear;
}
.keypad-button:hover {
  opacity: 0.8;
}
.keypad-button:focus,
.noBorder:focus {
  outline: none;
}
.keypad-button.green {
  background-color: #1fa220;
  color: #fff;
  margin: 10px;
  border-radius: 100px;
}

.keypad-button.red {
  background-color: #dc2f43;
  color: #fff;
  width: 12%;
  border-radius: 30px;
}

.keypad-button.mute_button {
  background-color: #bbb6b7;
  color: #fff;
  width: 12%;
  border-radius: 30px;
}
/* Keyboard End*/

/* On Call */
.onCall {
  display: flex;
  flex-direction: column;
  margin: 10px;
  align-items: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 1s;
  transform-style: preserve-3d;
  box-shadow: rgba(0, 0, 0, 0.1) 0.5px 0.5px 3px;
  padding: 20px 0px;
}

.onCall > div:nth-child(1) {
  display: flex;
  justify-content: center;
  background-color: #feefce;
  color: #d89d2e;
  width: 25%;
  padding: 10px;
  border-radius: 3px;
}
.onCall > div:nth-child(1) > i > span {
  color: black;
}

.onCall > div:nth-child(3) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.onCall > div:nth-child(3) > div:nth-child(1) {
  border-radius: 999px;
  padding: 10px 20px;
  background-color: gray;
  color: white;
  margin: 0 15px;
  font-size: x-large;
  transition: all 0.2s linear;
}

.onCall > div:nth-child(3) > div:nth-child(1):hover {
  opacity: 0.8;
}

.onCall > div:nth-child(3) > div:nth-child(2) {
  border-radius: 999px;
  padding: 10px 15px;
  background-color: red;
  color: white;
  font-size: x-large;
  margin: 0 15px;
  transition: all 0.2s linear;
}

.onCall > div:nth-child(3) > div:nth-child(2):hover {
  opacity: 0.8;
}

.onCall > div:nth-child(3) > i {
  border-radius: 999px;
  padding: 8px 10px;
  /* background-color: gray; */
  background-color: gray;
  color: white;
  font-size: 25px;
  margin: 0 15px;
  transition: all 0.2s linear;
}

.onCall > div:nth-child(3) > i:hover {
  opacity: 0.8;
}

.onCall > div:nth-child(3) > i > img {
  width: 35px;
  height: 35px;
  color: white;
}

.caller {
  display: flex;
  justify-content: space-between;
  padding: 10px 5px;
  align-items: center;
  border-radius: 5px;
  margin: 5px 10px;
}
/* First section */
.caller > div:nth-child(1) {
  font-size: xxx-large;
  padding: 25px 50px;
  border-radius: 999px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: aliceblue;
}
.caller > div:nth-child(1) > div {
  font-size: x-large;
}
/* Second section */
.caller > div:nth-child(2) {
  margin: 0 100px;
}

/* Third section */
.caller > div:nth-child(3) {
  font-size: xxx-large;
  padding: 25px 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 999px;
  background-color: aliceblue;
}
.caller > div:nth-child(3) > div {
  font-size: x-large;
}

.lds-ellipsiss {
  display: flex;
}
.lds-ellipsiss div {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: black;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsiss div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsisA 1s infinite;
}
.lds-ellipsiss div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsisB 0.8s infinite;
}
.lds-ellipsiss div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsisC 0.8s infinite;
}
.lds-ellipsiss div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsisD 0.8s infinite;
}
@keyframes lds-ellipsisA {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsisC {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsisD {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsisB {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(35px, 0);
  }
}

.callDialog {
  display: flex;

  position: fixed;
  top: 80px;
  right: 15px;
  z-index: 100;

  align-items: center;
  flex-direction: column;
  width: fit-content;
  background-color: #f7f8f9;
  border-radius: 5px;
  border: 1px solid lightgray;
}

.blurScreen > img {
  width: 20%;
  height: 30%;
}

.callDialog > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.callDialog > div:nth-child(1) > div:nth-child(1) {
  position: absolute;
  background-color: #f7f8f9;
  opacity: 0;
  height: 120px;
  width: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.5s;
}

.callDialog > div:nth-child(1) > div:nth-child(1) > i {
  font-size: xx-large;
}

.callDialog > div:nth-child(1) > div:nth-child(2) {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 0.7;
  margin: 10px;
}

.callDialog > div:nth-child(1) > div:nth-child(2) > i {
  font-size: xx-large;
  background-color: rgb(223, 238, 250);
  padding: 15px;
  border-radius: 999px;
}

.callDialog > div:nth-child(1) > div:nth-child(2) > div:nth-child(2) {
  font-weight: bold;
}

.callDialog > div:nth-child(2) {
  display: flex;
  width: 100%;
  flex: 0.3;
}

.callDialog > div:nth-child(2) > button:nth-child(1),
.callDialog > div:nth-child(2) > button:nth-child(2) {
  flex: 0.5;
  margin: 5px;
  display: flex;
  align-items: center;
  transition: all 0.2s linear;
}

.callDialog > div:nth-child(2) > button:nth-child(1):hover,
.callDialog > div:nth-child(2) > button:nth-child(2):hover {
  opacity: 0.75;
}

.fa-phone {
  transform: rotate(90deg);
}
/* On Call End */

/* Phone */
.call {
  text-align: center;
}
.status {
  text-align: center;
  background: #f4f4f6;
  display: block;
  width: 200px;
  padding: 0.5em;
  border-radius: 6px;
  margin: 2em auto 0;
}
.phone {
  display: flex;
  width: 35%;
  flex-direction: column;
  justify-content: center;
}

.phone_header {
  display: flex;
  flex-direction: column;
}

.backToCall {
  color: #d89d2e;
  width: fit-content;
  cursor: pointer;
  padding: 2px 10px;
  margin: 10px 0px 0px 10px;
  border: 1px solid #eabd6b;
  border-radius: 999px;
  transition: all 0.12s linear;
}

.backToCall:hover {
  background-color: #f1c269;
  color: white;
  border: 1px solid #f1c269;
}

.backToCall > i {
  margin-right: 10px;
}

.phone_header > div:nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.disableTab {
  pointer-events: none;
  opacity: 0.8;
}
/* Phone End */

/* Recent Calls */

.recent {
  display: flex;
  justify-content: center;
  margin: 15px;
  font-weight: bold;
  font-size: large;
}
.recent_calls {
  width: 40%;
  background-color: #fdfdfd;
  display: flex;
  padding: 10px 20px;
  height: 40em;
  margin-left: 6%;
  border-radius: 5px;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: rgba(0, 0, 0, 0.1) 0.5px 0.5px 3px;
}

.searchCall {
  display: flex;
  align-items: center;
  width: 80%;
  border: none;
  border-radius: 5px;
  padding: 15px;
  box-shadow: rgba(0.2, 0.2, 0.2, 0.1) 0px 1px 2px 0px;
  margin: 10px 0;
  margin-left: 10%;
}

.searchCall > i {
  margin-right: 8px;
  color: rgb(113, 112, 112);
}

.searchCall > input {
  border: none;
  width: 100%;
  outline: none;
  height: 100%;
  background-color: #fdfdfd;
}

.recent_items {
  display: flex;
  align-items: center;
  padding: 15px;
  width: 100%;
  font-size: medium;
  background-color: white;
  border-radius: 2px;
  margin: 3px;
  box-shadow: rgba(0, 0, 0, 0.1) 0.5px 0.5px 1px;
}

.recent_items > div:nth-child(1) {
  flex: 0.1;
  background-color: aliceblue;
  padding: 10px 5px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  font-size: xx-large;
}

.recent_items > div:nth-child(2) {
  flex: 0.8;
  margin-left: 10px;
  font-weight: bold;
  font-size: medium;
}

.recent_items > div:nth-child(3) {
  font-size: small;
  flex: 0.1;
}

.recent_items > div:nth-child(4) {
  flex: 0.1;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 100%;
  font-weight: bold;
  transition: all 0.2s linear;
}

.completed {
  background-color: rgb(32, 162, 32);
  box-shadow: rgba(0, 0, 0, 0.1) 1.95px 1.95px 2.6px;
}

.in_progress {
  background-color: #ecc163;
  box-shadow: rgba(0, 0, 0, 0.1) 1.95px 1.95px 2.6px;
}

.recent_items > div:nth-child(4):hover {
  opacity: 0.8;
}

.date_separator {
  font-weight: bold;
  margin: 10px;
}
/* Recent Calls End */
